import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import Big from "big.js";

import { useActiveCompany } from "components/companies";
import { isEditorRole } from "lib/roles";
import { LedgerAccountCategory } from "graphql/types";

import {
  useGetChartOfAccountsWithReconciliationQuery,
  useLedgerReconciliationQuery,
} from "./graphql.generated";
import { calcDepositsAndPayments } from "./ledgerReconUtils";
import { SingleLedgerReconciliationFragment } from "./graphql.generated";

const useReconViewerContextValue = ({
  accountSlug,
  reconciliationSlug,
}: {
  accountSlug?: string;
  reconciliationSlug?: string;
}) => {
  const { company, membershipRole } = useActiveCompany<true>();
  const [inMemoryFile, setInMemoryFile] = useState<File>();
  const [sidebarOpen, setSidebarOpen] = useLocalStorage("pz:ledger-recon-sidebar-open", false); // default to closed

  const { data: coaData, loading: coaDataLoading } = useGetChartOfAccountsWithReconciliationQuery({
    variables: {
      id: company.id,
    },
    context: { batch: false },
  });

  const {
    data: reconData,
    loading: reconDataLoading,
    refetch: refetchRecons,
  } = useLedgerReconciliationQuery({
    skip: !reconciliationSlug,
    variables: {
      id: reconciliationSlug ?? "",
    },
  });

  const activeLedgerAccount = coaData?.company?.chartOfAccounts.find(({ id }) =>
    reconciliationSlug ? id === reconData?.ledgerReconciliation.ledgerAccountId : id === accountSlug
  );

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [setSidebarOpen, sidebarOpen]);

  const isEditor = useMemo(() => isEditorRole(membershipRole), [membershipRole]);
  const activeRecon: SingleLedgerReconciliationFragment | undefined =
    reconData?.ledgerReconciliation;
  const isCreditCard = activeLedgerAccount?.category === LedgerAccountCategory.CreditCard;
  // TODO important - statement balances need to be updated to be positive-trending for cc
  // like account balances
  const statementBalance = isCreditCard
    ? Big(activeRecon?.statementBalance ?? 0).neg()
    : Big(activeRecon?.statementBalance ?? 0);

  const prevBalance = isCreditCard
    ? Big(activeRecon?.lastLedgerReconciliation?.statementBalance ?? 0).neg()
    : Big(activeRecon?.lastLedgerReconciliation?.statementBalance ?? 0);

  const [totalDeposits, setTotalDeposits] = useState<Big>(Big(0));
  const [totalPayments, setTotalPayments] = useState<Big>(Big(0));

  useEffect(() => {
    if (activeRecon?.balanceSummary) {
      const ledgerDeposits = Big(activeRecon?.balanceSummary?.totalDeposits.amount ?? 0);
      const ledgerPayments = Big(activeRecon?.balanceSummary?.totalPayments.amount ?? 0);
      const { totalDeposits, totalPayments } = calcDepositsAndPayments(
        isCreditCard,
        ledgerDeposits,
        ledgerPayments
      );
      setTotalDeposits(totalDeposits);
      setTotalPayments(totalPayments);
    }
  }, [activeRecon?.balanceSummary, isCreditCard]);

  const reconciledBalance = prevBalance.add(totalDeposits).minus(totalPayments);

  const totalDifference = statementBalance.minus(reconciledBalance);

  return useMemo(
    () => ({
      activeLedgerAccount,
      accountSlug,
      reconciliationSlug,
      isEditor,
      inMemoryFile,
      setInMemoryFile,
      sidebarOpen,
      toggleSidebar,
      activeRecon,
      reconDataLoading,
      coaDataLoading,
      statementBalance,
      prevBalance,
      totalDifference,
      totalDeposits,
      totalPayments,
      setTotalPayments,
      setTotalDeposits,
      reconciledBalance,
      isCreditCard,
      refetchRecons,
    }),
    [
      activeLedgerAccount,
      accountSlug,
      reconciliationSlug,
      isEditor,
      inMemoryFile,
      setInMemoryFile,
      sidebarOpen,
      toggleSidebar,
      activeRecon,
      reconDataLoading,
      coaDataLoading,
      statementBalance,
      prevBalance,
      totalPayments,
      totalDeposits,
      setTotalPayments,
      setTotalDeposits,
      totalDifference,
      reconciledBalance,
      isCreditCard,
      refetchRecons,
    ]
  );
};

const ReconViewerContext = React.createContext<ReturnType<
  typeof useReconViewerContextValue
> | null>(null);

export const useReconViewerContext = () => {
  const context = React.useContext(ReconViewerContext);

  if (context === null) {
    throw new Error(
      "useReconViewerContext must be used as a child within ReconViewerContextProvider"
    );
  }

  return context;
};

export const ReconViewerProvider = ({
  accountSlug,
  reconciliationSlug,
  children,
}: React.PropsWithChildren<{
  accountSlug?: string;
  reconciliationSlug?: string;
}>) => {
  return (
    <ReconViewerContext.Provider
      value={useReconViewerContextValue({ accountSlug, reconciliationSlug })}
    >
      {children}
    </ReconViewerContext.Provider>
  );
};

export default ReconViewerContext;
