import Big, { BigSource } from "big.js";
import { useFile } from "components/common/files";
import { useActiveCompany } from "components/companies";
import { AssociatedEntity } from "graphql/types";
import Analytics from "lib/analytics";
import useAppRouter from "lib/useAppRouter";
import { useCallback, useEffect, useState } from "react";
import { useReconViewerContext } from "../ReconContext";
import {
  GetLedgerReconciliationsDocument,
  useStartLedgerReconciliationMutation,
} from "../graphql.generated";
import { RECON_BUFFER_DAY_COUNT, getReconPath } from "../shared";
import { CalendarDateString } from "scalars";
import { useToasts } from "@puzzle/ui";

export const useStartReconciliation = () => {
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [createdReconId, setCreatedReconId] = useState<string | undefined>();
  const { activeLedgerAccount, isCreditCard, inMemoryFile } = useReconViewerContext();
  const { goToPath } = useAppRouter();
  const [_startRecon, { data, loading, error, called }] = useStartLedgerReconciliationMutation({
    refetchQueries: [GetLedgerReconciliationsDocument],
  });

  const { onFiles, isUploading } = useFile({
    entityId: createdReconId,
    entityType: AssociatedEntity.LedgerReconciliation,
    onUploadComplete: ([file]) => {
      if (createdReconId) goToPath(getReconPath(createdReconId));

      Analytics.accountingRecordAttachmentUploaded({
        id: createdReconId ?? "",
        contentType: file.contentType,
        fileName: file.filename ?? "",
        fileSize: file.size,
        uploadEntityType: AssociatedEntity.LedgerReconciliation,
      });
    },
  });

  useEffect(() => {
    if (createdReconId && inMemoryFile && !isUploading) {
      onFiles([inMemoryFile]);
    }
  }, [createdReconId, inMemoryFile, onFiles, isUploading]);

  const startRecon = useCallback(
    ({
      endingBalance,
      endingDate,
    }: {
      endingBalance: BigSource;
      endingDate: CalendarDateString;
    }) => {
      if (!activeLedgerAccount) return null;
      return _startRecon({
        variables: {
          input: {
            companyId: company.id,
            ledgerAccountId: activeLedgerAccount.id,
            statementBalance: isCreditCard
              ? // TODO - statement balances should use positive-trending balances
                // not negative - update all statement balances
                Big(endingBalance).neg().toString()
              : endingBalance.toString(),
            statementDay: endingDate,
            view: company.ledgerReconciliationBasis,
            bufferDayCount: RECON_BUFFER_DAY_COUNT,
          },
        },
        onCompleted: ({ startLedgerReconciliation }) => {
          const id = startLedgerReconciliation.ledgerReconciliation.id;

          setCreatedReconId(id);

          if (!inMemoryFile) goToPath(getReconPath(id));

          Analytics.startedLedgerReconciliation({
            id,
            accountId: activeLedgerAccount.id,
          });
        },
        onError: ({ message }) => {
          Analytics.startedLedgerReconciliationError({ accountId: activeLedgerAccount.id });

          toast({
            title: "Starting reconciliation failed. Please try again.",
            message,
            status: "error",
          });
        },
      });
    },
    [
      activeLedgerAccount,
      company.id,
      _startRecon,
      goToPath,
      inMemoryFile,
      company.ledgerReconciliationBasis,
      isCreditCard,
      toast,
    ]
  );

  return {
    startRecon,
    loading,
    startReconCalled: called,
    startReconError: error,
    createdRecon: data?.startLedgerReconciliation.ledgerReconciliation,
    isUploading,
  };
};
