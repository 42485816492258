import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { CalendarDateString } from "scalars";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Big from "big.js";

import { CurrencyInput, Field, Button, DateInput, Help, Text, styled } from "@puzzle/ui";
import { toCalendarDate, today, parseDate, CalendarDate, getLocalTimeZone } from "@puzzle/utils";

import { useActiveCompany } from "components/companies";
import { MoneyFragment } from "graphql/types";
import { useReconViewerContext } from "../ReconContext";
import { InputBox } from "../shared";
import { useStartReconciliation } from "./useStartReconciliation";
import { Box, S, color, fontWeights, vars } from "ve";
import { format } from "date-fns";

export const SubText = styled(Text, {
  color: "$gray500",
  fontSize: "$bodyXS",
  fontStyle: "italic",
});

type FormValues = {
  endingBalance: number;
  endingDate: CalendarDateString;
};

const validationSchema = yup.object({
  endingBalance: yup
    .number()
    .required()
    .test("endingBalance", (value) => typeof value !== "undefined"),
  endingDate: yup
    .string()
    .required()
    .test("date", (value) => Boolean(value && parseDate(value))),
});

export const StartReconForm = ({
  suggestedBalance,
  suggestedEndDate,
}: {
  suggestedEndDate?: CalendarDate;
  suggestedBalance?: MoneyFragment;
}) => {
  const { timeZone } = useActiveCompany<true>();
  const { activeLedgerAccount } = useReconViewerContext();

  const lastReconciledDateStr =
    activeLedgerAccount?.ledgerReconciliationSummary?.lastReconciled?.statementDate ?? "";

  const lastReconciledDate = useMemo(() => {
    return lastReconciledDateStr ? parseDate(lastReconciledDateStr) : undefined;
  }, [lastReconciledDateStr]);

  const lastReconciledDateFormatted = useMemo(() => {
    return lastReconciledDate
      ? format(lastReconciledDate.toDate(getLocalTimeZone()), "MM/dd/yyyy")
      : null;
  }, [lastReconciledDate]);

  const { startRecon, isUploading, loading } = useStartReconciliation();
  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = useMemo(() => {
    return form.handleSubmit(async (formValues) => {
      const { endingBalance, endingDate } = formValues;
      return startRecon({ endingBalance, endingDate });
    });
  }, [form, startRecon]);

  if (!activeLedgerAccount) return null;

  return (
    <>
      <Box
        css={{
          display: "flex",
          background: color.mauve850,
          color: color.gray400,
          fontSize: vars.fontSizes.bodyXS,
          fontWeight: fontWeights.bold,
          padding: S["1"],
          gap: S["1"],
        }}
      >
        <Text>Create a new reconciliation</Text>
        <Help content="We auto-select entries with Puzzle dates up to the selected statement date. However, you may need to modify the selections based on your statement. You may also need to add or remove transactions if any transactions are missing or duplicated." />
      </Box>

      <Box css={{ padding: S["1h"], gap: S["1"], display: "flex", flexDirection: "column" }}>
        <Box css={{ color: color.gray300, fontSize: vars.fontSizes.bodyXS }}>
          Reconciliations are based on your statement or account activity.{" "}
          {lastReconciledDateStr
            ? `This account was previously reconciled through: ${lastReconciledDateFormatted}`
            : null}
        </Box>
        <Box css={{ display: "flex", gap: S["1"], alignItems: "end" }}>
          <InputBox>
            <Field label="Statement ending balance">
              <Controller
                control={form.control}
                defaultValue={
                  suggestedBalance ? Big(suggestedBalance?.amount).toNumber() : undefined
                }
                name="endingBalance"
                render={({ field }) => {
                  return (
                    <CurrencyInput
                      size="small"
                      textAlign="right"
                      truncateWhenInactive={false}
                      ref={field.ref}
                      value={field.value}
                      onValueChange={({ value }) => {
                        return field.onChange({
                          target: { value, name: field.name },
                        });
                      }}
                    />
                  );
                }}
              />
            </Field>
          </InputBox>
          <InputBox>
            <Field label="Statement ending date">
              <Controller
                control={form.control}
                name="endingDate"
                defaultValue={suggestedEndDate?.toString()}
                render={({ field }) => {
                  return (
                    <DateInput
                      minDate={lastReconciledDate}
                      maxDate={today(timeZone)}
                      size="small"
                      placeholder="Pick a date"
                      value={field.value ? parseDate(field.value) : undefined}
                      onChange={(value) => {
                        field.onChange({
                          target: {
                            value: value && toCalendarDate(value).toString(),
                            name: field.name,
                          },
                        });
                      }}
                    />
                  );
                }}
              />
            </Field>
          </InputBox>
          <Button
            size="compact"
            variant="primary"
            disabled={!form.formState.isValid}
            loading={loading || isUploading}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};
