import { styled, Root as InputRoot } from "@puzzle/ui";
import { SettingType } from "components/settings/Automation/types";
import { Route } from "lib/routes";

export const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$3",
});

export const InputBox = styled("div", {
  flex: 1,

  [`${InputRoot}`]: {
    height: "32px",
  },
});

export function getNewReconPath(accountId: string) {
  return Route.newLedgerReconciliation.replace(":accountId", accountId);
}

export function getReconPath(reconId: string) {
  return Route.ledgerReconciliationViewer.replace(":reconciliationId", reconId);
}

export function getReconListByAccountPath(accountId: string) {
  return Route.ledgerReconciliationsByAccount.replace(":accountId", accountId);
}

export const RECON_BUFFER_DAY_COUNT = 3;

export function getAutoBalanceBannerLocalStorageKey(companyId: string) {
  return `pz:auto-balance-${SettingType.AutoBalanceAdjustments}-${companyId}-setting-warning`;
}
